import sanityClient from "../sanityClient";
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(sanityClient);

export function urlFor(source) {
	return builder.image(source).url();
}

export async function fetchCategories() {
	return sanityClient.fetch('*[_type == "taskCategories"]');
}

export async function fetchTasks() {
	return sanityClient.fetch(
		'*[_type == "tasks" && !(_id in path("drafts.**"))]'
	);
}

export async function fetchTaskList() {
	return sanityClient.fetch('*[_type == "taskList"]');
}

export async function fetchCarouselItems() {
	return sanityClient.fetch(
		'*[_type == "carousel"][0]{ title, banners[]->{ title, description, image, ctaText, url } }'
	);
}
