import React, { useState } from "react";
import {
  useTheme,
  Typography,
  Paper,
  Box,
  useMediaQuery,
  Button,
} from "@mui/material";
import Carousel from "react-material-ui-carousel";
import imageUrlBuilder from "@sanity/image-url";
import sanityClient from "@/sanityClient";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const CustomCarousel = ({ items }) => {
  const theme = useTheme();
  const [firstImageLoaded, setFirstImageLoaded] = useState(false);

  return (
    <Box
      sx={{
        borderRadius: 20,
        position: "relative",
        width: "100%",
        height: "400px",
        borderRadius: "30px",
        overflow: "hidden",
      }}
    >
      <Carousel
        autoPlay={true}
        swipe={true}
        cycleNavigation={true}
        animation="slide"
        navButtonsAlwaysInvisible
        interval={15000}
        duration={500}
        indicators={false}
        height="400px"
        stopAutoPlayOnHover
      >
        {items.map((item, index) => (
          <CarouselItem
            key={index}
            item={item}
            theme={theme}
            isFirstImage={index === 0}
            setFirstImageLoaded={setFirstImageLoaded}
            firstImageLoaded={firstImageLoaded}
          />
        ))}
      </Carousel>
    </Box>
  );
};

const CarouselItem = ({
  item,
  theme,
  isFirstImage,
  setFirstImageLoaded,
  firstImageLoaded,
}) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleImageLoad = () => {
    if (isFirstImage) {
      setFirstImageLoaded(true);
    }
  };

  // If the item is a custom React element, return it directly
  if (item.type === "custom") {
    return (
      <Box
        className={"fade-in-delay"}
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          opacity: 0,
          transition: "all 1s ease-in-out",
        }}
      >
        {item.content}
      </Box>
    );
  }

  return (
    <Box
      className={"fade-in-delay"}
      sx={{
        position: "relative",
        opacity: 0,
        transition: "all 1s ease-in-out",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <img
        src={urlFor(item.image).url()}
        alt={item.title}
        onLoad={handleImageLoad}
        style={{
          width: "100%",
          height: "400px",
          objectFit: "cover",
          borderRadius: 4,
          pointerEvents: "none",
        }}
      />
      <Paper
        sx={{
          boxShadow: "none",
          position: "absolute",
          bottom: "30px",
          right: "30px",
          padding: "30px",
          borderRadius: 5,
          maxWidth: "500px",
          minWidth: isSmallScreen ? "200px" : "400px",
          backgroundColor:
            theme.palette.mode === "dark"
              ? "rgba(0, 0, 0, 0.93)"
              : "rgba(255, 255, 255, 0.93)",
          boxShadow:
            theme.palette.mode === "dark"
              ? "5px 5px 60px rgba(0,0,0, 1)"
              : "0px 0px 40px rgba(0, 0, 0, .5)",
          color: theme.palette.mode === "dark" ? "white" : "black",
          "@media (max-width: 600px)": {
            bottom: "15px",
            left: "15px",
            right: "15px",
            padding: "20px",
          },
        }}
      >
        <Box>
          <Typography
            variant={isSmallScreen ? "h5" : "h4"}
            mb={1}
            sx={{ cursor: "default" }}
          >
            {item.title}
          </Typography>
          <Typography variant="body2" sx={{ cursor: "default" }}>
            {item.description}
          </Typography>
          <Button
            onClick={() => {
              const isExternal =
                item.url.startsWith("http") || item.url.startsWith("https");
              window.open(item.url, isExternal ? "_blank" : "_self");
            }}
            sx={{ marginTop: "20px" }}
            variant="contained"
          >
            {item.ctaText}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default CustomCarousel;
